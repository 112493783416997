@font-face {
    font-family: 'Linotte Semi Bold';
    src: url('/assets/fonts/Linotte-Semibold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  
  .linotte-font {
    font-family: 'Linotte Semi Bold', sans-serif;
    font-weight: 600; /* Semi Bold */
  }

.logo_color{
    filter: invert(1)
}

/* navbar.css */
.logo-straighten {
  transform: rotate(9deg); /* Ajustez l'angle si nécessaire */
}
